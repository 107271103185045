import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { LocationsCounters } from 'features/Configurateur/components/LocationsCounters';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
  name: string;
};

export const ModificationsRadiateurEau: React.FC<Props> = ({ configuration, name }) => (
  <>
    <QuestionTitle prefix="3" title="Modifications à faire ?" />
    <LocationsCounters
      configuration={configuration}
      locations={configuration.locations}
      getFields={locationUuid => [
        {
          label: 'Remplacement radiateur à eau',
          name: `${name}.locationQuantities.${locationUuid}.remplacementRadiateurs`,
        },
        {
          label: 'Ajout seul radiateur à eau',
          name: `${name}.locationQuantities.${locationUuid}.ajoutRadiateurs`,
        },
        {
          label: 'Supression seule radiateur',
          name: `${name}.locationQuantities.${locationUuid}.suppressionRadiateursEau`,
        },
        { label: "Vannes d'arrêt", name: `${name}.locationQuantities.${locationUuid}.vannes` },
        {
          label: 'Robinets thermostatiques',
          name: `${name}.locationQuantities.${locationUuid}.robinets`,
        },
        { label: 'Peinture', name: `${name}.locationQuantities.${locationUuid}.peintures` },
      ]}
    />
  </>
);
