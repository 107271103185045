import React from 'react';

import { Navigate, useNavigate, useOutletContext } from 'react-router';

import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { FournituresWithTracking } from '@travauxlib/shared/src/features/Fournitures/components/FournituresWithTracking';
import { AdminEventsProperties } from '@travauxlib/shared/src/utils/tracking';

import { useAuth } from 'api/useAuth';
import { ProjectContext } from 'features/Project/api/useProjectContext';
import { useFournitures } from 'features/Project/features/ProjectPage/features/ProjectFourniture/api/useFournitures';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ListeFournituresTracking } from 'utils/tracking/ListeFournituresTracking';

export const ProjectFournitures: React.FC = () => {
  const { project, chantiers } = useOutletContext<ProjectContext>();
  const { fournitures, isLoading } = useFournitures(project.uuid);
  const {
    adminUserProfile: { uuid },
  } = useAuth();
  const isAllowedActionsForThisFournitureList = useIsAllowed(
    'Fourniture',
    'manage',
    chantiers.some(chantier => chantier.responsableSuiviUuid === uuid) ? ['isAssignedTo'] : [],
  );

  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  if (fournitures.length === 0) {
    return <Navigate to=".." />;
  }

  return (
    <FournituresWithTracking
      fournitureList={fournitures}
      containerClassName="sm-desktop:py-md"
      userType="admin"
      editOrAddReferenceAction={{
        onClick: (fournitureUuid: string) =>
          navigate(`/projects/${project.uuid}/fournitures/${fournitureUuid}/reference`),
        disabled: !isAllowedActionsForThisFournitureList,
      }}
      doTracking={() => {
        ListeFournituresTracking.onFournituresListOpened({
          [AdminEventsProperties.ProjetUUID]: project.uuid,
        });
        UserTracking.startSessionRecording();
      }}
    />
  );
};
